<template>
    <div class="container mx-auto" id="gallery-container">

        <div class="mt-6 md:mt-16 px-6 md:px-16" v-if="!isLoaded">
            <list-loader :width="400" :height="160" :speed="1" :animate="true" primaryColor="lightgray" secondaryColor="gray"></list-loader>
        </div>
        <div class="flex flex-col items-center h-screen justify-center px-6 md:px-16" v-else-if="!$auth.check() && !passwordVerified">
            <h2 class="text-3xl uppercase font-bold leading-tight tracking-wider">Please enter password below to view this gallery</h2>
            <div class="mt-6 max-w-xs">
                <form method="post" @submit.prevent="verifyPassword">
                    <input type="password" class="w-full bg-white p-2 rounded focus:outline-none font-work-sans text-black text-xl mb-4" placeholder="Password" required ref="gallery_password_input" />
                    <button class="bg-white text-black font-dense tracking-medium text-2xl font-bold border-b-3 border-turquoise leading-snug pt-2 outline-none focus:outline-none block text-center px-4 uppercase w-full relative" :class="{'cursor-not-allowed':verifyPasswordLoading}" type="submit">Verify <span class="spinner" v-if="verifyPasswordLoading"><span></span><span></span><span></span><span></span></span></button>
                    <p class="text-black text-xl sm:text-xl leading-tight font-bold tracking-wide font-work-sans mt-4" v-if="passwordError"><span class="text-red-600">Invalid password.</span></p>
                </form>
            </div>
        </div>
        <div class="mt-6 md:mt-16 px-6 md:px-16" v-else-if="preset!=null">

            <div class="text-center py-6 px-3 logo-wrapper">
                <img class="inline" alt="logo" :src="apiUrl+'/media/'+preset.dataCaptureSettings.logo" v-if="preset.dataCaptureSettings && preset.dataCaptureSettings.logo && preset.dataCaptureSettings.logo!==''" />
                <h1 class="text-6xl uppercase font-bold leading-tight tracking-wide" v-else-if="preset.dataCaptureSettings && preset.dataCaptureSettings.client && preset.dataCaptureSettings.client.Name!==''">{{preset.dataCaptureSettings.client.Name}}</h1>
            </div>

            <h1 class="font-bold text-40px uppercase tracking-wider mb-12">Gallery - <span v-text="preset.title"></span></h1>

            <div class="flex justify-between mb-6" v-if="images.length > 0 && $auth.check()">
                <button class="bg-white text-black font-dense tracking-medium text-xl font-bold border-b-3 border-turquoise leading-snug pt-2 pb-1 outline-none focus:outline-none block text-center px-4 uppercase" @click="selectDeslectAll"><span v-text="selectAll ? 'Deselect All' : 'Select All'">Select All</span> <font-awesome-icon :icon="['far', 'check-square']" class="ml-1 text-lg"></font-awesome-icon></button>
                <button class="bg-white text-black font-dense tracking-medium text-xl font-bold border-b-3 border-turquoise leading-snug pt-2 pb-1 outline-none focus:outline-none block text-center px-4 uppercase" @click="shareGallery=true">Share Gallery <font-awesome-icon :icon="['fas', 'share-square']" class="ml-1 text-lg"></font-awesome-icon></button>
            </div>

            <CoolLightBox 
                :items="images" 
                :index="lightBoxIndex"
                :fullScreen="true"
                :slideshow="false"
                :closeOnClickOutsideMobile="true"
                :gallery="false"
                @close="lightBoxIndex = null"
                v-if="images.length > 0">
                <template v-slot:close>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"></path></svg>
                </template>
                <template v-slot:loading>
                    <div class="lightbox-loading"></div>
                </template>
                <template v-slot:icon-previous>
                    <font-awesome-icon :icon="['fas', 'chevron-left']"></font-awesome-icon>
                </template>
                <template v-slot:icon-next>
                    <font-awesome-icon :icon="['fas', 'chevron-right']"></font-awesome-icon>
                </template>
            </CoolLightBox>

            <div class="grid grid-cols-1 md:grid-cols-4 gap-6" v-if="images.length > 0">
                <div class="mb-6 relative overflow-hidden transition duration-500 image-wrapper" v-for="(image, imageIndex) in images" :key="image.id">
                    <div class="hidden absolute z-20 checkbox-wrapper" @click="deSelectImage">
                        <input type="checkbox" name="imageId[]" :value="image.id" class="hidden" />
                        <div class="bg-white w-6 h-6 border border-turquoise text-center"><font-awesome-icon :icon="['fas', 'check']" class="text-turquoise text-sm"></font-awesome-icon></div>
                    </div>
                    <Slothloader class="transition-all duration-500" :src="image.src" :omitAspect=true :fadeIn=true  />
                    <div class="flex justify-between absolute left-0 px-3 w-full space-x-2 transform translate-y-16 transition-all duration-500 view-select-buttons">
                        <button class="bg-white text-black font-dense tracking-medium text-xl font-bold border-b-3 border-turquoise leading-snug pt-2 pb-1 outline-none focus:outline-none block text-center w-1/2 uppercase" @click="lightBoxIndex = imageIndex" :class="{'mx-auto': !$auth.check()}">View</button>
                        <button class="bg-white text-black font-dense tracking-medium text-xl font-bold border-b-3 border-turquoise leading-snug pt-2 pb-1 outline-none focus:outline-none block text-center w-1/2 uppercase" @click="selectImage" v-if="$auth.check()">Select</button>
                    </div>
                    <div class="hidden justify-between absolute left-0 px-3 w-full deselect-button" v-if="$auth.check()">
                        <button class="bg-white text-black font-dense tracking-medium text-xl font-bold border-b-3 border-turquoise leading-snug pt-2 pb-1 outline-none focus:outline-none block text-center w-full uppercase" @click="deSelectImage">Deselect</button>
                    </div>
                </div>
            </div>
            <div class="text-2xl font-bold tracking-wider uppercase" v-else>
                There are no media items to display in this gallery.
            </div>

            <div class="fixed z-30 bg-white text-black font-dense tracking-medium text-xl font-bold border-b-4 border-turquoise leading-snug pt-2 w-full bottom-0 left-0 uppercase transform transition-all duration-500" :class="[ (itemsSelected > 0) ? 'translate-y-0' : 'translate-y-full' ]"  v-if="$auth.check()">
                <div class="container mx-auto px-6 md:px-16">
                    <div class="flex flex-row flex-nowrap justify-between items-center">
                        <div>{{ itemsSelected }} Items selected</div>
                        <div>
                            <div class="flex flex-row">
                                <button class="bg-white text-black font-dense tracking-medium text-xl font-bold leading-snug outline-none focus:outline-none block text-center uppercase" @click="downloadImages">Download <font-awesome-icon :icon="['fas', 'download']" class="ml-2 text-lg align-text-top"></font-awesome-icon></button>
                                <div class="text-gray-600 mx-4 text-xl leading-loose font-thin">|</div>
                                <button class="bg-white text-red-600 font-dense tracking-medium text-xl font-bold leading-snug outline-none focus:outline-none block text-center uppercase" @click="confirmDelete=true">Delete <font-awesome-icon :icon="['far', 'trash-alt']" class="ml-2 text-lg align-text-top"></font-awesome-icon></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="mt-6 md:mt-16 px-6 md:px-16" v-else>
            <h2 class="font-bold text-40px uppercase tracking-wider">Invalid Link or some error. Please contact support.</h2>
        </div>

        <modal :showing="confirmDelete" transition="scale" :closeBtn="false" overlay-bg-class="bg-black bg-opacity-50" modal-bg-class="bg-white" border-class="border-b-3 border-turquoise" class="text-center" v-if="$auth.check()">
            <template v-if="deletingWait === true">
                <div class="text-black text-2xl sm:text-4xl uppercase leading-tight font-bold tracking-wide mb-2">Please wait...</div>
            </template>
            <template v-else-if="deleteError === true">
                <div class="text-black text-3xl sm:text-4xl uppercase leading-tight font-bold tracking-wide mb-2">Error</div>
                <p class="text-black text-xl sm:text-2xl uppercase leading-tight font-bold tracking-wide text-opacity-75"><span class="text-red-600">An error occurred while processing your delete request.</span></p>
            </template>
            <template v-else>
            <div class="text-black text-3xl sm:text-4xl uppercase leading-tight font-bold tracking-wide mb-2">Are You sure?</div>
            <p class="text-black text-xl sm:text-2xl uppercase leading-tight font-bold tracking-wide text-opacity-75">You're about to <span class="text-red-600">delete</span> all selected photos<br>Are you sure you want to do this?</p>
            <br>
            <div class="flex -mx-2">
                <a href="#" class="bg-white text-black font-dense tracking-wider text-2xl font-bold border border-gray-400 leading-tight pt-2 pb-1 outline-none w-1/2 mx-2" @click.prevent="confirmDelete=false">CANCEL</a>
                <a href="#" class="bg-red-600 text-white font-dense tracking-wider text-2xl font-bold border-b-0 border-turquoise leading-tight pt-2 pb-1 outline-none w-1/2 mx-2" @click.prevent="deleteSelectedMedia">DELETE</a>
            </div>
            </template>
        </modal>

        <modal :showing="shareGallery" transition="scale" :closeBtn="false" overlay-bg-class="bg-black bg-opacity-50" modal-bg-class="bg-white" border-class="border-b-3 border-turquoise" class="text-center" v-if="$auth.check()">
            <button
                    aria-label="close"
                    class="absolute top-0 right-0 text-3xl text-black my-6 mx-8 uppercase font-bold tracking-wider focus:outline-none modal-rounded-close-btn"
                    @click.prevent="shareGallery=false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="padding:2px"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"></path></svg>
            </button>
            <div class="text-black text-2xl sm:text-4xl uppercase leading-tight font-bold tracking-wide mb-2">Share link</div>
            <p class="text-black text-lg sm:text-xl uppercase leading-tight font-bold tracking-wider text-opacity-75">Publicly share this link to anyone<br>so they can view your gallery</p>
            <div class="mt-6 border border-gray-300 h-10 text-gray-700 text-lg text-left pl-3 pr-10 align-middle leading-loose overflow-hidden truncate relative font-work-sans">
                {{ currentUrl }}
                <button class="absolute text-turquoise right-0 text-base mr-3 mt-1 focus:outline-none" @click="copyShareUrl">
                    <font-awesome-icon :icon="['far', 'copy']" v-if="!copied"></font-awesome-icon>
                    <font-awesome-icon :icon="['fas', 'check']" class="text-green-600" v-if="copied"></font-awesome-icon>
                </button>
            </div>
        </modal>

        <modal :showing="downloadingImages" transition="scale" :closeBtn="false" overlay-bg-class="bg-black bg-opacity-50" modal-bg-class="bg-white" border-class="border-b-3 border-turquoise" class="text-center" v-if="$auth.check()">
            <div class="text-black text-2xl sm:text-4xl uppercase leading-tight font-bold tracking-wide mb-2">Please wait...</div>
            <p class="text-black text-lg sm:text-xl uppercase leading-tight font-bold tracking-wider text-opacity-75">Please wait while we are fetching and compressing selected images. It may take a while depending on total images.</p>
        </modal>

    </div>
</template>


<script>

    import { ListLoader } from "vue-content-loader";

    import Slothloader from 'sloth-loader/index.vue';

    import CoolLightBox from 'vue-cool-lightbox';

    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

    import Modal from '@/components/Modal.vue';

    import { saveAs } from 'file-saver';

    import JSZip from 'jszip'
    import JSZipUtils from 'jszip-utils'

    export default {
        name: 'Gallery',
        data() {
            return {
                apiUrl: process.env.VUE_APP_BOOTHCLUBAPP_API_ENDPOINT,
                isLoaded: false,
                id: null,
                preset: null,
                images: [],
                selectAll: false,
                itemsSelected: 0,
                lightBoxIndex: null,
                confirmDelete: false,
                deletingWait: false,
                deleteError: false,
                shareGallery: false,
                currentUrl: '',
                copied: false,
                passwordVerified: false,
                verifyPasswordLoading: false,
                passwordError: false,
                downloadingImages: false
            }
        },
        components: {
            ListLoader,
            Slothloader,
            CoolLightBox,
            Modal
        },
        beforeMount: function() {
            document.body.id = 'gallery-page';
        },
        beforeDestroy: function() {
            document.body.id = '';
            document.documentElement.style.setProperty('--background-color', '');
            document.documentElement.style.setProperty('--color', '');
        },
        mounted: function() {
            if( typeof this.$route.params.id !== 'undefined' ) {
                this.id = this.$route.params.id;
            }
            if( this.id !== null && ( this.$auth.check() || this.passwordVerified === true ) ) {
                this.getGalleryImages(this.id);
            } else {
                this.isLoaded = true;
            }
            this.currentUrl = window.location.href;
        },
        methods: {
            getGalleryImages: function(id) {
                const app = this;
                this.$http({
                    url: 'get-gallery-images/'+id,
                    method: 'get'
                }).then(function(response) {
                    app.preset = response.data.preset;

                    app.preset.appDesignSettings.backgroundColorHexValue = '#'+app.preset.appDesignSettings.backgroundColorHexValue;
                    app.preset.appDesignSettings.textColorHexValue = '#'+app.preset.appDesignSettings.textColorHexValue;

                    if(app.preset.appDesignSettings.backgroundColorHexValue !== '#000000') {
                        document.documentElement.style.setProperty('--background-color', app.preset.appDesignSettings.backgroundColorHexValue);
                    }
                    document.documentElement.style.setProperty('--color', app.preset.appDesignSettings.textColorHexValue);

                    app.preset.dataCaptureSettings.client = {
                        Name: response.data.client.Name,
                        privacyPolicyURL: response.data.client.privacyPolicyURL
                    };

                    //set full url of the image for vue cool lightbox
                    response.data.images.forEach(function(image) {
                        app.images.push({id: image, src: app.apiUrl+'/media/'+image+'?gallery=true'});
                        //app.images.push({id: image.id, src: 'https://boothclub-galleries.s3.eu-west-2.amazonaws.com/'+image.filename});
                    });

                    app.isLoaded = true;

                }).catch(function() {
                    app.isLoaded = true;
                    //app.$router.push({name: 'dashboard'});
                });
            },
            selectImage: function(event) {
                let parentElement = event.target.closest('.image-wrapper');
                parentElement.classList.add('selected');
                parentElement.parentElement.classList.add('selection-on');
                parentElement.querySelector('.checkbox-wrapper input').checked = true;
                this.updateItemsSelected();
            },
            deSelectImage: function(event) {
                this.selectAll = false;
                let parentElement = event.target.closest('.image-wrapper');
                parentElement.classList.remove('selected');
                parentElement.querySelector('.checkbox-wrapper input').checked = false;
                this.updateItemsSelected();
                if( this.itemsSelected === 0 ) {
                    parentElement.parentElement.classList.remove('selection-on');
                }
            },
            selectDeslectAll: function() {
                this.selectAll = !this.selectAll;
                if( this.selectAll === true ) {
                    document.querySelectorAll('.image-wrapper').forEach(function(element) {
                        element.classList.add('selected');
                        element.querySelector('.checkbox-wrapper input').checked = true;
                    });
                } else {
                    document.querySelectorAll('.image-wrapper').forEach(function(element) {
                        element.classList.remove('selected');
                        element.querySelector('.checkbox-wrapper input').checked = false;
                    });
                }
                this.updateItemsSelected();
                if( this.itemsSelected === 0 ) {
                    document.querySelector('.image-wrapper').parentElement.classList.remove('selection-on');
                } else {
                    document.querySelector('.image-wrapper').parentElement.classList.add('selection-on');
                }
            },
            updateItemsSelected: function() {
                this.itemsSelected = document.querySelectorAll('.image-wrapper.selected').length;
            },
            deleteSelectedMedia: function() {

                this.deletingWait = true;
                let selectedItems = document.querySelectorAll('.image-wrapper.selected input:checked'), mediaItemsId = [], app = this;

                if(selectedItems.length > 0) {

                    selectedItems.forEach(function(selectedItem) {
                        mediaItemsId.push(selectedItem.value);
                    });

                    this.$http({
                        url: 'delete-media-item',
                        method: 'POST',
                        data: {
                            'media_items': mediaItemsId
                        }
                    })
                    .then(function() {
                        selectedItems.forEach(function(selectedItem) {
                            app.images = app.images.filter(function(image) {
                                return image.id !== selectedItem.value;
                            });
                        });
                        app.itemsSelected = 0;
                        app.confirmDelete = false;
                    })
                    .catch(function() {
                        app.deleteError = true;
                        setTimeout(function() {
                            app.confirmDelete = false;
                            app.deleteError = false;
                        }, 3000);
                    })
                    .finally(function() {
                        app.deletingWait = false;
                    })
                }
            },
            copyShareUrl: function() {
                let app = this;
                this.$copyText(window.location.href).then(function() {
                    app.copied = true;
                    setTimeout(function() {
                        app.copied = false;
                    }, 3000);
                });
            },
            verifyPassword: function() {
                this.verifyPasswordLoading = true;
                let app = this;
                this.$http({
                    url: 'verify-gallery-password',
                    method: 'POST',
                    data: {
                        'password': this.$refs.gallery_password_input.value
                    }
                })
                .then(function() {
                    app.isLoaded = false;
                    app.verifyPasswordLoading = false;
                    app.passwordVerified = true;
                    app.getGalleryImages(app.id);
                })
                .catch(function() {
                    app.verifyPasswordLoading = false;
                    app.passwordError = true;
                    setTimeout(function() {
                        app.passwordError = false;
                    }, 3000);
                })
            },
            downloadImages: function() {
                let selectedItems = document.querySelectorAll('.image-wrapper.selected input:checked'), app = this, selectedImages = [];

                selectedItems.forEach(function(selectedItem) {
                    selectedImages.push(app.images.filter(function(image) {
                        return image.id === selectedItem.value;
                    })[0]);
                });
                
                if(selectedImages.length == 1) {
                    this.$http({
                        method: 'get',
                        url: selectedImages[0].src,
                        responseType: 'blob',
                        transformRequest: (data, headers) => {
                            delete headers['common'];
                            delete headers['authorization'];
                            delete headers['Cache-Control'];
                            return data;
                        }
                    })
                    .then(function(response) {
                        saveAs(response.data, new URL(selectedImages[0].src).pathname.split('/').pop()+'.png');
                        app.selectAll = true;
                        app.selectDeslectAll();
                    });
                } else {
                    let zip = new JSZip(), counter = 0;
                    this.downloadingImages = true;
                    selectedImages.forEach(function(image) {
                        JSZipUtils.getBinaryContent(image.src, function (err, data) {
                            if(err) {
                                //console.log(err);
                                err;
                            } else {
                                zip.file(new URL(image.src).pathname.split('/').pop()+'.png', data, {binary:true});
                            }
                            counter++;
                            if (counter == selectedImages.length) {
                                zip.generateAsync({type:'blob'}).then(function(content) {
                                    saveAs(content, 'gallery');
                                    app.downloadingImages = false;
                                    app.selectAll = true;
                                    app.selectDeslectAll();
                                });
                            }
                        })
                    });
                }
            }
        },
    }

</script>

<style scoped>
#gallery-container {
    color: var(--color, #FFFFFF);
}
.logo-wrapper img {
	max-height: 200px;
}
.sloth-loader:not(.loaded) {
    padding-top: 50%;
    padding-bottom: 50%;
}
.sloth-loader:not(.loaded):after, .lightbox-loading {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  /* margin: 8px; */
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  margin:0 auto;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.image-wrapper {
    border:1px solid transparent;
}
.image-wrapper:hover, .image-wrapper.selected {
    border:1px solid #0DE9FF;
}
.image-wrapper .checkbox-wrapper {
    top: 10px;
    left: 10px;
}
.image-wrapper .view-select-buttons, .image-wrapper .deselect-button {
    bottom: 10px;
}
.image-wrapper:hover .view-select-buttons {
    @apply translate-y-0
}
.image-wrapper:not(.selected):hover .sloth-loader { /*, .image-wrapper.selected .sloth-loader {*/
    @apply opacity-75;
}
.selection-on .image-wrapper:not(.selected) .sloth-loader {
    opacity: 0.40;
}
.image-wrapper.selected .view-select-buttons {
    @apply hidden;
}
.image-wrapper.selected .deselect-button, .image-wrapper.selected .checkbox-wrapper {
    @apply flex;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.spinner {
    position: absolute;
    top: 25%;
    left: auto;
    right: 25%;
    transition-property: padding, opacity;
    transition-duration: 0.2s, 0.2s;
    transition-timing-function: ease-in, ease;
    transition-delay: 0s, 0.2s;
}
.spinner span {
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0.15rem;
    width: 1.5rem;
    height: 1.5rem;
    opacity: 1;
    border: 3.4px solid #0DE9FF;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #0DE9FF transparent transparent transparent;
}
.spinner span:nth-child(1) {
    animation-delay: 0.45s;
}
.spinner span:nth-child(2) {
    animation-delay: 0.3s;
}
.spinner span:nth-child(3) {
    animation-delay: 0.15s;
}
</style>

<style>
body.bg-black#gallery-page {
    background: var(--background-color, rgba(0,0,0,0.9));
}
.cool-lightbox-toolbar .cool-lightbox-toolbar__btn {
    @apply bg-white text-black mt-2 mr-2 rounded-full;
    border:1px solid #0DE9FF;
    width: 28px;
    height: 28px;
    padding: 4px;
}
.cool-lightbox-toolbar .cool-lightbox-toolbar__btn:hover, .cool-lightbox .cool-lightbox-button:hover {
    @apply text-black
}
.cool-lightbox .cool-lightbox-button, .modal-rounded-close-btn {
    @apply bg-white text-black rounded-full;
    border:1px solid #0DE9FF;
    width: 28px;
    height: 28px;
    line-height: 28px;
    padding: 0 !important;
}
.cool-lightbox .cool-lightbox-button.cool-lightbox-button--prev {
    padding-right: 2px !important;
    @apply ml-2;
}
.cool-lightbox .cool-lightbox-button.cool-lightbox-button--next {
    padding-left: 2px !important;
    @apply mr-2;
}
</style>